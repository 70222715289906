export const lightThemeColors = {
  primary: "#FFFFFF",
  secondary: "#000000",
  hover: "#FF6B00",
};

export const darkThemeColors = {
  primary: "#201C1C",
  secondary: "#FFFFFF",
  hover: "#FF6B00",
};
