import React from "react";
import EmailIcon from "../../assets/images/Acceron/Footer/email-icon.png";
import CallIcon from "../../assets/images/Acceron/Footer/call-icon.png";
import "./index.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "../../../utils/ThemeContext";
import { darkThemeColors, lightThemeColors } from "../../../utils/colors";
import { getContrastColor } from "../../../utils/ColorUtils";
import AcceronLightLogo from "../../assets/images/Acceron/Header/light_mode_logo.svg";
import AcceronDarkLogo from "../../assets/images/Acceron/Header/dark_mode_logo.svg";
import InstagramLightIcon from "../../assets/images/Acceron/Footer/instagram_light.svg";
import InstagramDarkIcon from "../../assets/images/Acceron/Footer/instagram_dark.svg";
import FacebookLightIcon from "../../assets/images/Acceron/Footer/facebook_light.svg";
import FacebookDarkIcon from "../../assets/images/Acceron/Footer/facebook_dark.svg";
import LinkedInLightIcon from "../../assets/images/Acceron/Footer/linkedin_light.svg";
import LinkedInDarkIcon from "../../assets/images/Acceron/Footer/linkedin_dark.svg";
import TwitterLightIcon from "../../assets/images/Acceron/Footer/twitter_light.svg";
import TwitterDarkIcon from "../../assets/images/Acceron/Footer/twitter_dark.svg";

function Footer() {
  const { theme } = useTheme();
  const themeColors = theme === "light" ? lightThemeColors : darkThemeColors;
  const primaryColor = themeColors.primary;
  const secondaryColor = themeColors.secondary;
  const textColor = getContrastColor(secondaryColor);

  const handleEmailLinkClick = () => {
    const email = "info@acceron.co";
    window.open(`mailto:${email}`);
  };

  return (
    <div
      className="row footer-container"
      style={{ backgroundColor: primaryColor, color: secondaryColor }}
    >
      <div className="col-xs-12 footer-main">
        <div className="footer-content-container">
          <div style={{ width: "30%" }}>
            <Button
              component={Link}
              to="/home"
              style={{ marginBottom: "11px" }}
            >
              {theme === "light" ? (
                <img
                  src={AcceronLightLogo}
                  alt=""
                  className="footer-acceron-logo"
                />
              ) : (
                <img
                  src={AcceronDarkLogo}
                  alt=""
                  className="footer-acceron-logo"
                />
              )}
            </Button>
            <h4
              style={{
                fontFamily: "Oxanium",
                fontWeight: "normal",
                fontSize: "13px",
                lineHeight: "23px",
                marginBottom: "18px",
              }}
            >
              Empowering Performance through Acceron's Cutting-Edge Lubrication
              Solutions.
            </h4>
            <div
              className="d-flex justify-content-between align-items-center footer-social-links"
              style={{ width: "45%", marginBottom: "18px" }}
            >
              <Link
                to="https://www.instagram.com/acceronindustries/"
                target={"new"}
              >
                <Button
                  style={{ width: "24px", height: "24px", padding: "2px" }}
                >
                  {theme === "light" ? (
                    <img src={InstagramDarkIcon} alt="" />
                  ) : (
                    <img src={InstagramLightIcon} alt="" />
                  )}
                </Button>
              </Link>

              <Button style={{ width: "24px", height: "24px", padding: "2px" }}>
                {theme === "light" ? (
                  <img src={FacebookDarkIcon} alt="" />
                ) : (
                  <img src={FacebookLightIcon} alt="" />
                )}
              </Button>
              <Button style={{ width: "24px", height: "24px", padding: "2px" }}>
                {theme === "light" ? (
                  <img src={TwitterDarkIcon} alt="" />
                ) : (
                  <img src={TwitterLightIcon} alt="" />
                )}
              </Button>
              <Link
                to="https://www.linkedin.com/company/acceron-industries/"
                target={"new"}
              >
                <Button
                  style={{ width: "24px", height: "24px", padding: "2px" }}
                >
                  {theme === "light" ? (
                    <img src={LinkedInDarkIcon} alt="" />
                  ) : (
                    <img src={LinkedInLightIcon} alt="" />
                  )}
                </Button>
              </Link>
            </div>
            <h5
              style={{
                fontFamily: "Oxanium",
                fontWeight: "medium",
                fontSize: "12px",
                // marginBottom: "18px",
              }}
            >
              © Copyright 2025 Acceron. All Rights Reserved. Privacy Policy
            </h5>
          </div>
          <div className="d-flex footer-pages-section">
            <h2 style={{ color: secondaryColor }}>Pages</h2>
            <div className="flex items-start gap-40">
              <div className="flex flex-col items-start">
                <a href="/home">
                  <Button>
                    <p style={{ color: secondaryColor }}>Home</p>
                  </Button>
                </a>
                <a href="/about_us">
                  <Button>
                    <p style={{ color: secondaryColor }}>About Us</p>
                  </Button>
                </a>
                <a href="/product-category">
                  <Button>
                    <p style={{ color: secondaryColor }}>Products</p>
                  </Button>
                </a>
                <a href="/careers">
                  <Button>
                    <p style={{ color: secondaryColor }}>Careers</p>
                  </Button>
                </a>
              </div>
              <div className="flex flex-col items-start">
                <a href="/privacy_policy">
                  <Button>
                    <p style={{ color: secondaryColor }}>Privacy Policy</p>
                  </Button>
                </a>
                <a href="/cookie_policy">
                  <Button>
                    <p style={{ color: secondaryColor }}>Cookie Policy</p>
                  </Button>
                </a>
                <a href="/terms">
                  <Button>
                    <p style={{ color: secondaryColor }}>Terms & Conditions</p>
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-links-section">
            <h2 style={{ color: secondaryColor }}>Stay Connected</h2>
            <div className="footer-links-container">
              <div className="d-flex justify-content-between align-items-center footer-link">
                <Button onClick={handleEmailLinkClick}>
                  <img src={EmailIcon} alt="" />
                  <p style={{ color: secondaryColor }}>info@acceron.co</p>
                </Button>
              </div>
              <div className="d-flex justify-content-between align-items-center footer-link footer-call-link">
                <Button>
                  <img src={CallIcon} alt="" />
                  <a href="tel:+918305096448" style={{ color: secondaryColor }}>
                    +91 8305096448
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content-container-mobile">
          <Button component={Link} to="/home">
            {theme === "light" ? (
              <img
                src={AcceronLightLogo}
                alt=""
                className="footer-acceron-logo"
              />
            ) : (
              <img
                src={AcceronDarkLogo}
                alt=""
                className="footer-acceron-logo"
              />
            )}
          </Button>
        </div>
        <div className="footer-links-container-mobile">
          <div className="footer-links-section">
            <h2 style={{ color: secondaryColor }}>Stay Connected</h2>
            <div className="footer-links-container">
              <div className="d-flex justify-content-between align-items-center footer-link">
                <Button>
                  <img src={EmailIcon} alt="" />
                  <p style={{ color: secondaryColor }}>info@acceron.co</p>
                </Button>
              </div>
              <div className="d-flex justify-content-between align-items-center footer-link footer-call-link">
                <Button>
                  <img src={CallIcon} alt="" />
                  <a href="tel:+918305096448" style={{ color: secondaryColor }}>
                    +91 8305096448
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
